
module.exports = {
  pathPrefix: '/gatsby-starter-developer-blog',
  siteUrl: 'https://www.domingorivera.tech',
  siteTitle: 'Domingo Rivera',
  siteDescription: 'Technology, Law, Forensics',
  author: 'domingo rivera',
  postsForArchivePage: 3,
  defaultLanguage: 'en',
  //disqusScript: 'https://.disqus.com/embed.js',
  pages: {
    home: '/',
    blog: '/',
    about: 'about',
    tag: 'tag',
    archive: 'archive'
  },
  social: {
    github: 'https://www.domingorivera.org/',
    linkedin: 'https://www.linkedin.com/in/domingo-rivera-28848526',
    rss: '/rss.xml'
  },
  tags: {
    forensics: {
      description: 'Posts about digital forensics and cyber investigations.'
    },
    technology: {
      description: 'Posts about technology and tech issues'
    },
    privacy: {
      description: 'Posts about privacy law and cyber privacy topics.'
    },
    domingorivera: {
      description: 'Posts recommended by cyber lawyer and computer forensics expert Domingo Rivera.'
    },
    defamation: {
      description: 'Posts about online defamation.'
    },
    cyberlaw: {
      description: 'Posts about Cyber Law and Internet cases.'
    },
    policy: {
      description: 'Posts about politics and policy.'
    },
    security: {
      description: 'Posts about cyber security.'
    },
    other: {
      description: 'Random posts and posts that do not fit the other categories.'
    }
  }
}