/* Vendor imports */
import React from 'react'
/* App imports */
import style from './footer.module.less'

const Footer = () => (
  <div className={style.container}>
    <p>Copyright Domingo J Rivera. All Rights Reserved.</p>
  </div>
)

export default Footer
